import goalSeek from "goal-seek";

function resolve(fn, fnParams, target) {
  try {
    const goal = goalSeek({
      fn,
      fnParams,
      percentTolerance: 1,
      maxIterations: 1000,
      maxStep: 10000,
      goal: target,
      independentVariableIdx: 0,
    });

    return parseFloat(goal);
  } catch (e) {
    return 0;
  }
}

export const calculation = (values, settings, grade) => {
  const result = {};

  // *********************************************
  // PREPARATION
  // *********************************************

  // *** ARBEITSTAGE ***
  const stunden_pt = values.stunden_pw / 5;
  const stunden_pw = values.stunden_pw;

  // *** JAHRESSTUNDEN ***
  const stunden_pj = (values.stunden_pw * settings.arbeitswochen_pj * (1 - settings.bezahlte_feiertage) * (1 - settings.ferienrueckstellung.nongav[values.age]) * (1 - settings.sonstige_absenzen)); // prettier-ignore
  const stunden_pm = stunden_pj / 12;

  // *** MULTIPLIER ***
  const multiplier_options = {
    pro_stunde: stunden_pj,
    pro_tag: stunden_pj / stunden_pt,
    pro_monat: 12,
    pro_jahr: 1,
  };

  const multiplier = multiplier_options[values.target_pro];

  // *** GAV ***
  // Bei Schwellwerten zugunsten GAV
  let settings_ferienrückstellung = 0;
  let settings_dreizehnter = 0;
  if ((values.target / stunden_pj) * multiplier < settings.gav_threshold) {
    settings_ferienrückstellung = settings.ferienrueckstellung.gav[values.age];
    settings_dreizehnter = settings.dreizehnter;
  } else {
    settings_ferienrückstellung = settings.ferienrueckstellung.nongav[values.age];
    settings_dreizehnter = 0;
  }

  // *** RÜCKSTELLUNGEN ABSENZEN PROZENT ***
  const bezahlte_feiertage = settings.bezahlte_feiertage;
  const ferienrueckstellung = (1 + bezahlte_feiertage) * settings_ferienrückstellung;
  const dreizehnter = (1 + bezahlte_feiertage + ferienrueckstellung) * settings_dreizehnter;
  const sonstige_absenzen = (1 + bezahlte_feiertage + ferienrueckstellung + dreizehnter) * settings.sonstige_absenzen; // prettier-ignore
  const rs_absenzen_percentage =  bezahlte_feiertage + ferienrueckstellung + dreizehnter + sonstige_absenzen // prettier-ignore

  // CONSOLE
  console.log("***********************");
  console.log("Bezahlte Feiertage:", settings.bezahlte_feiertage);
  console.log("Ferienrückstellung:", settings_ferienrückstellung);
  console.log("Dreizehnter:", settings_dreizehnter);
  console.log("Sonstige:", settings.sonstige_absenzen);
  console.log("***********************");

  // BASISSATZ
  if (values.target_for === "basissatz") {
    result.basissatz = parseFloat(values.target);
  } else if (values.target_for === "bruttolohn") {
    let x = values.target;
    const fn = (x) => x * (1 + rs_absenzen_percentage); // prettier-ignore
    const fnParams = [x];
    result.basissatz = resolve(fn, fnParams, x);
  } else {
    result.basissatz = 0;
  }

  // RUECKSTELLUNG ABSENZEN
  result.rs_absenzen = result.basissatz * rs_absenzen_percentage;
  result.bezahlte_feiertage = result.basissatz * bezahlte_feiertage;
  result.ferienrueckstellung = result.basissatz * ferienrueckstellung;
  result.dreizehnter = result.basissatz * dreizehnter;
  result.sonstige_absenzen = result.basissatz * sonstige_absenzen;
  console.log(typeof result.rs_absenzen, typeof result.basissatz, result.rs_absenzen);

  // BRUTTOLOHN
  result.bruttolohn = result.basissatz + result.rs_absenzen;

  // *********************************************
  // BVG VERSICHERTER LOHN
  // *********************************************

  // PREPARATION
  const bruttolohn_jahr = result.bruttolohn * multiplier;
  const uvg_regulaer = Math.min(settings.lohnkennzahlen.max_vers_uvg_lohn, bruttolohn_jahr) / multiplier; // prettier-ignore
  const uvg_ueberregulaer = Math.max(bruttolohn_jahr - settings.lohnkennzahlen.max_vers_uvg_lohn, 0) / multiplier // prettier-ignore
  let ktg_regulaer = Math.min(bruttolohn_jahr, settings.lohnkennzahlen.min_vers_ktg_lohn);
  console.log();
  const uvg_ueberschuss = Math.max(ktg_regulaer - settings.lohnkennzahlen.max_vers_uvg_lohn, 0) / multiplier; // prettier-ignore
  ktg_regulaer = ktg_regulaer / multiplier;

  // *** BVG VERSICHERTER OBLIGATORISCHER LOHN (bvg_vol) ***
  result.bvg_vol = 0;
  if (bruttolohn_jahr >= settings.lohnkennzahlen.mindestjahreslohn) {
    // Abzug des Koordinationsabzugs
    result.bvg_vol = bruttolohn_jahr - settings.lohnkennzahlen.koordinationsabzug;
    // Sicherstellen, dass der Lohn in innerhalb der Koordinationsschranken zuliegen kommt
    result.bvg_vol = Math.max(result.bvg_vol, settings.lohnkennzahlen.min_koord_lohn);
    result.bvg_vol = Math.min(result.bvg_vol, settings.lohnkennzahlen.max_koord_lohn);
  }

  // *** BVG VERSICHERTER ÜBEROBLIGATORISCHER LOHN (bvg_vuel) ***
  result.bvg_vuel = bruttolohn_jahr - result.bvg_vol - settings.lohnkennzahlen.koordinationsabzug;
  // Sicherstellen, dass es nicht unter 0 oder grösser als der BVG Überschuss Maximallohn ausfällt
  result.bvg_vuel = Math.max(result.bvg_vuel, 0);
  result.bvg_vuel = Math.min(result.bvg_vuel, settings.lohnkennzahlen.bvg_maximallohn * 10);

  // Speichern in gewählter Periodizität
  result.bvg_vol = result.bvg_vol / multiplier;
  result.bvg_vuel = result.bvg_vuel / multiplier;

  // *** GAV BASIS (gav_basis) ***
  result.gav_basis = bruttolohn_jahr / stunden_pj > settings.gav_threshold ? 0 : result.bruttolohn;

  // *********************************************
  // BEITRÄGE ARBEITGEBER
  // *********************************************
  result.ag = {};

  // *** BVG OBLIGATORIUM RISIKO (bvg_or) ***
  result.ag.bgv_or = result.bvg_vol * settings.versicherungspraemie[grade].arbeitgeber.bvg_obligatorium;

  // *** BVG OBLIGATORIUM SPAREN (bvg_os) ***
  const ag_altersgutschrift = settings.altersgutschrift[grade].arbeitgeber[values.age];
  console.log("ALTER", ag_altersgutschrift);
  result.ag.bgv_os = result.bvg_vol * ag_altersgutschrift;

  // *** BVG OBLIGATORIUM ÜBER-OBLIGATORIUM (bvg_ueo) ***
  result.ag.bgv_ueo = result.bvg_vuel * settings.versicherungspraemie[grade].arbeitgeber.bvg_ueberobligatorium  ; // prettier-ignore

  // *** AHV / UV / EO (ahv) ***
  result.ag.ahv = result.bruttolohn * settings.sozialversicherung.arbeitgeber.ahv;

  // *** AHV VERWALTUNGSKOSTEN (ahv_verwaltung) ***
  result.ag.ahv_verwaltung = result.bruttolohn * settings.sozialversicherung.arbeitgeber.ahv_verwaltungskosten  ; // prettier-ignore

  // *** FAMILIENAUSGLEISKASSE FAK (fak) ***
  result.ag.fak = result.bruttolohn * settings.sozialversicherung.arbeitgeber.fak;

  // *** ALV I BEITRAG BIS CHF 148'200 PRO JAHR (alv1) ***
  result.ag.alv1 = uvg_regulaer * settings.sozialversicherung.arbeitgeber.alv1;

  // *** ALV II BEITRAG AB CHF 148'200 PRO JAHR (alv2) ***
  result.ag.alv2 = uvg_ueberregulaer * settings.sozialversicherung.arbeitgeber.alv2;

  // *** UVG - BERUFSUNFALL BIS CHF 148'200 PRO JAHR (uvg_bu) ***
  result.ag.uvg_bu = uvg_regulaer * settings.versicherungspraemie[grade].arbeitgeber.uvg_berufsunfall  ; // prettier-ignore

  // *** UVG - ZUSATZVERSICHERUNG BIS CHF 148'200 PRO JAHR (uvg_zv) ***
  result.ag.uvg_zv = uvg_regulaer * settings.versicherungspraemie[grade].arbeitgeber.uvg_zusatzversicherung  ; // prettier-ignore

  // *** UVG - ÜBERSCHUSSLOHN AB CHF 148'200 BIS 315'000 PRO JAHR (uvg_uel)***
  result.ag.uvg_uel = uvg_ueberschuss * settings.versicherungspraemie[grade].arbeitgeber.uvg_ueberschusslohn  ; // prettier-ignore

  // *** KTG BIS MAX CHF 315'000 PRO JAHR (ktg)***
  const ag_ktg_praemie = settings.ktg[grade].arbeitgeber[values.sex];
  result.ag.ktg_regulaer = ktg_regulaer * ag_ktg_praemie  ; // prettier-ignore

  // *** KANTONALER BERUFSBILDUNGSBEITRAG (kant_bbb)***
  result.ag.kant_bbb = result.bruttolohn * settings.weiteres.arbeitgeber.kant_berufsbildung  ; // prettier-ignore

  // *** BERUFSBEITRAG GAV PERSONALVERLEIH BIS CHF 148'200 PRO JAHR (bbb)***
  result.ag.bbb = result.gav_basis * settings.weiteres.arbeitgeber.berufsbeitrag  ; // prettier-ignore

  result.ag_total = 0;
  console.log(
    "SHOW AG",
    result.bvg_vuel,
    settings.versicherungspraemie[grade].arbeitgeber.bvg_obligatorium,
    result.bruttolohn
  );
  for (let index in result.ag) {
    result.ag_total += result.ag[index];
    console.log("CALC", index, result.ag[index]);
  }
  console.log("TOTAL", result.ag_total);

  // *********************************************
  // BEITRÄGE ARBEITNEHMER
  // *********************************************

  result.an = {};

  // *** BVG OBLIGATORIUM RISIKO (bvg_or) ***
  result.an.bgv_or = result.bvg_vol * settings.versicherungspraemie[grade].arbeitgeber.bvg_obligatorium;

  // *** BVG OBLIGATORIUM SPAREN (bvg_os) ***
  const an_altersgutschrift = settings.altersgutschrift[grade].arbeitnehmer[values.age];
  result.an.bgv_os = result.bvg_vol * an_altersgutschrift;

  // *** BVG OBLIGATORIUM ÜBER-OBLIGATORIUM (bvg_ueo) ***
  result.an.bgv_ueo = result.bvg_vuel * settings.versicherungspraemie[grade].arbeitnehmer.bvg_ueberobligatorium  ; // prettier-ignore

  // *** AHV / UV / EO (ahv) ***
  result.an.ahv = result.bruttolohn * settings.sozialversicherung.arbeitnehmer.ahv;

  // *** ALV I BEITRAG BIS CHF 148'200 PRO JAHR (alv1) ***
  result.an.alv1 = uvg_regulaer * settings.sozialversicherung.arbeitnehmer.alv1;

  // *** ALV II BEITRAG AB CHF 148'200 PRO JAHR (alv2) ***
  result.an.alv2 = uvg_ueberregulaer * settings.sozialversicherung.arbeitnehmer.alv2;

  // *** UVG - UVG - NICHTBERUFSUNFALL BIS CHF 148'200 PRO JAHR (uvg_nbu) ***
  result.an.uvg_nbu = uvg_regulaer * settings.versicherungspraemie[grade].arbeitnehmer.uvg_nichtberufsunfall  ; // prettier-ignore

  // *** UVG - ÜBERSCHUSSLOHN AB CHF 148'200 BIS 315'000 PRO JAHR (uvg_uel)***
  result.an.uvg_uel = uvg_ueberschuss  * settings.versicherungspraemie[grade].arbeitnehmer.uvg_ueberschusslohn  ; // prettier-ignore

  // *** KTG BIS MAX CHF 315'000 PRO JAHR (ktg)***
  const an_ktg_praemie = settings.ktg[grade].arbeitnehmer[values.sex];
  result.an.ktg_regulaer = ktg_regulaer * an_ktg_praemie  ; // prettier-ignore

  // *** BERUFSBEITRAG GAV PERSONALVERLEIH BIS CHF 148'200 PRO JAHR (bbb)***
  result.an.bbb = result.gav_basis * settings.weiteres.arbeitnehmer.berufsbeitrag  ; // prettier-ignore

  result.an_total = 0;
  console.log("SHOW AN", result.bvg_vuel, settings.versicherungspraemie[grade].arbeitgeber.bvg_obligatorium);
  for (let index in result.an) {
    result.an_total += result.an[index];
    console.log("CALC", index, result.an[index]);
  }

  // *********************************************
  // MSP / PAYROLL FEE
  // *********************************************

  if ((values.payrolling_fee = "pauschal")) {
    result.payrolling_fee = (stunden_pj / multiplier) * settings.payrolling_fee_pauschal;
  } else {
    result.payrolling_fee = (result.bruttolohn + result.ag_total) * settings.payrolling_fee_all_in;
  }
  result.msp_fee = (result.bruttolohn + result.ag_total + result.payrolling_fee) * (settings.msp_fee/(1 - settings.msp_fee)) ; // prettier-ignore

  console.log(
    "SHOW PAYROLL",
    result.bruttolohn + result.ag_total + result.payrolling_fee,
    result.payrolling_fee,
    settings.msp_fee
  );

  return result;
};
