import { StylesContext } from "@mui/styles";
import React, { useState } from "react";

// IMPORT COMPONENTS
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// IMPORT UTILS
import { format } from "../../utils/format.js";

// IMPORT STYLE
import "./Line.css";

const Line = (props) => {
  // PROPS
  const { content } = props;
  const { label_DE, grade1, grade4, grade9 } = content;
  console.log("CONTENT", content);
  console.log("CHEEEEEEEK", (34444444).toLocaleString());

  // STATE
  const [open, setOpen] = useState(false);

  // CALCULATION
  const containerClass = open ? "line_subContainerOpen" : "line_subContainerClosed";
  const iconClass = open ? "line_iconOpen" : "line_iconClosed";

  return (
    <>
      <TableBody className="line_container">
        <TableCell>
          <div className="line_label">
            <p>{label_DE}</p>
            {grade1.details ? (
              <div className={"line_icon " + iconClass}>
                <AddCircleIcon onClick={() => setOpen(!open)} />
              </div>
            ) : null}
          </div>
        </TableCell>
        <TableCell>
          <p className="line_number">{format(grade1.value)}</p>
        </TableCell>
        <TableCell>
          <p className="line_number">{format(grade4.value)}</p>
        </TableCell>
        <TableCell>
          <p className="line_number">{format(grade9.value)}</p>
        </TableCell>
      </TableBody>
      {grade1.details &&
        grade1.details.map((item, index) => {
          return (
            <TableBody key={index} className={"line_subContainer " + containerClass}>
              <TableCell>
                <div>
                  <p>{grade1.details[index].label_DE}</p>
                </div>
              </TableCell>
              <TableCell>
                <p className="line_number">{format(grade1.details[index].value)}</p>
              </TableCell>
              <TableCell>
                <p className="line_number">{format(grade4.details[index].value)}</p>
              </TableCell>
              <TableCell>
                <p className="line_number">{format(grade9.details[index].value)}</p>
              </TableCell>
            </TableBody>
          );
        })}
    </>
  );
};

export default Line;
