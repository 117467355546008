// IMPORT BASICS
import React, { useState } from "react";

// IMPORT COMPONENTS
import InputBase from "@mui/material/InputBase";
import MuiSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";

// IMPORT STYLES
import styles from "./Select.module.scss";

// ****************************************
// SELECT (Component)
// Draws the select field
// ****************************************
const Select = (props) => {
  // PROPS
  const { placeholder, name, title, options, updateValues } = props;

  // STATES
  const [item, setItem] = useState("");

  // FUNCTIONS
  const handleChange = (event) => {
    setItem(event.target.value);
    updateValues(name, event.target.value);
  };

  // MUI OVERRIDE
  const useStyles = makeStyles({
    select: {
      "&:after": {
        borderBottomColor: "darkred",
        display: "none",
      },
      "& .MuiSvgIcon-root": {
        color: "darkred",
        display: "none",
      },
    },
  });

  const classes = useStyles();

  // RENDER
  return (
    <div className={styles["container"]}>
      <label className={styles["title"]}>{title}</label>
      <div className={styles["field"]}>
        <MuiSelect
          className={classes.select}
          style={{ width: "100%" }}
          onChange={handleChange}
          value={item}
          input={<InputBase placeholder={placeholder} />}
        >
          {options.map((option) => {
            return <MenuItem value={option.value}>{option.label}</MenuItem>;
          })}
        </MuiSelect>
      </div>
    </div>
  );
};

export default Select;
