// IMPORT BASICS
import React, { useState } from "react";

// IMPORT COMPONENTS
import InputBase from "@mui/material/InputBase";

// IMPORT STYLES
import styles from "./TextField.module.scss";

// ****************************************
// TEXTFIELD (Component)
// Draws the textfield
// ****************************************
const TextField = (props) => {
  // PROPS
  const { placeholder, title, name, addon, updateValues } = props;

  // STATES
  const [value, setValue] = useState("");

  // FUNCTIONS
  const handleChange = (event) => {
    setValue(event.target.value);
    updateValues(name, event.target.value);
  };

  // OPERATIONS
  const paddingLeft = addon ? "72px" : "auto";

  // RENDER
  return (
    <div className={styles["container"]}>
      <label className={styles["title"]}>{title}</label>
      <div style={{ paddingLeft: paddingLeft }} className={styles["field"]}>
        {addon ? <p className={styles["addon"]}>CHF</p> : null}
        <InputBase onChange={handleChange} value={value} placeholder={placeholder} />
      </div>
    </div>
  );
};

export default TextField;
