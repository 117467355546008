export const getStructure = (values) => {
  const result = {
    ag: [],
    an: [],
  };

  let item;

  // **************************************
  // * ARBEITSGEBER
  // **************************************

  // *** AG BASISSATZ ***
  item = { label_DE: "Basissatz" };
  for (let key in values) {
    item[key] = {
      value: values[key].basissatz,
    };
  }
  result.ag.push(item);

  // *** AG RÜCKSTELLUNG ABSENZEN ***
  item = { label_DE: "+ Rückstellung Absenzen" };
  for (let key in values) {
    item[key] = {
      value: values[key].rs_absenzen,
      details: [
        { label_DE: "Bezahlte Freitage und Absenzen", value: values[key].bezahlte_feiertage },
        { label_DE: "Ferienrückstellung", value: values[key].ferienrueckstellung },
        { label_DE: "13. Monatslohn", value: values[key].dreizehnter },
        { label_DE: "Sonstige Absenzen & Aufschläge", value: values[key].sonstige_absenzen },
      ],
    };
  }
  result.ag.push(item);

  // *** AG BRUTTOLOHN***
  item = { label_DE: "= Bruttolohn" };
  for (let key in values) {
    item[key] = {
      value: values[key].bruttolohn,
    };
  }
  result.ag.push(item);

  // *** AG BEITRÄGE ARBEITGEBER***
  item = { label_DE: "+ Beiträge Arbeitgeber" };
  for (let key in values) {
    item[key] = {
      value: values[key].ag_total,
      details: [
        { label_DE: "BVG Obligatorium Risiko", value: values[key].ag.bgv_or },
        { label_DE: "BVG Obligatorium Sparen", value: values[key].ag.bgv_os },
        { label_DE: "BVG Über-Obligatorium", value: values[key].ag.bgv_ueo },
        { label_DE: "AHV / IV / EO", value: values[key].ag.ahv },
        { label_DE: "AHV Verwaltungskosten", value: values[key].ag.ahv_verwaltung },
        { label_DE: "Familienausgleichskasse FAK", value: values[key].ag.fak },
        { label_DE: "ALV I Beitrag bis CHF 148'200 pro Jahr", value: values[key].ag.alv1 },
        { label_DE: "ALV II Beitrag ab CHF 148'200 pro Jahr", value: values[key].ag.alv2 },
        { label_DE: "UVG - Berufsunfall bis CHF 148'200 pro Jahr", value: values[key].ag.uvg_bu },
        { label_DE: "UVG - Zusatzversicherung bis CHF 148'200 pro Jahr", value: values[key].ag.uvg_zv },
        { label_DE: "UVG - Überschusslohn ab CHF 148'200 bis 315'000 pro Jahr", value: values[key].ag.uvg_uel },
        { label_DE: "KTG bis max. CHF 315'000 pro Jahr", value: values[key].ag.ktg_regulaer },
        { label_DE: "Kantonaler Berufsbildungsbeitrag", value: values[key].ag.kant_bbb },
        { label_DE: "Berufsbeitrag GAV Personalverleih bis CHF 148'200 pro Jahr", value: values[key].ag.bbb },
      ],
    };
  }
  result.ag.push(item);

  // *** ALL IN RATE ***
  item = { label_DE: "= All-In Lohnkosten" };
  for (let key in values) {
    item[key] = {
      value: values[key].bruttolohn + values[key].ag_total,
    };
  }
  result.ag.push(item);

  // *** MSP FEE ***
  item = { label_DE: "+ MSP Fee" };
  for (let key in values) {
    item[key] = {
      value: values[key].msp_fee,
    };
  }
  result.ag.push(item);

  // *** PAYROLLING ***
  item = { label_DE: "+ Payrolling Fee / Marge" };
  for (let key in values) {
    item[key] = {
      value: values[key].payrolling_fee,
    };
  }
  result.ag.push(item);

  // *** BILL RATE ***
  item = { label_DE: "= Bill Rate (an den Kunden)" };
  for (let key in values) {
    item[key] = {
      value: values[key].bruttolohn + values[key].ag_total + values[key].msp_fee + values[key].payrolling_fee,
    };
  }
  result.ag.push(item);

  // **************************************
  // * ARBEITNEHMER
  // **************************************

  // *** AN BRUTTOLOHN***
  item = { label_DE: "= Bruttolohn" };
  for (let key in values) {
    item[key] = {
      value: values[key].bruttolohn,
    };
  }
  result.an.push(item);

  // *** ANBEITRÄGE ARBEITGEBER***
  item = { label_DE: "- Beiträge Arbeitnehmer" };
  for (let key in values) {
    item[key] = {
      value: -values[key].an_total,
      details: [
        { label_DE: "BVG Obligatorium Risiko", value: values[key].an.bgv_or },
        { label_DE: "BVG Obligatorium Sparen", value: values[key].an.bgv_os },
        { label_DE: "BVG Über-Obligatorium", value: values[key].an.bgv_ueo },
        { label_DE: "AHV / IV / EO", value: values[key].an.ahv },
        { label_DE: "ALV I Beitrag bis CHF 148'200 pro Jahr", value: values[key].an.alv1 },
        { label_DE: "ALV II Beitrag ab CHF 148'200 pro Jahr", value: values[key].an.alv2 },
        { label_DE: "UVG - Zusatzversicherung bis CHF 148'200 pro Jahr", value: values[key].an.uvg_nbu },
        { label_DE: "UVG - Überschusslohn ab CHF 148'200 bis 315'000 pro Jahr", value: values[key].an.uvg_uel },
        { label_DE: "KTG bis max. CHF 315'000 pro Jahr", value: values[key].an.ktg_regulaer },
        { label_DE: "Berufsbeitrag GAV Personalverleih bis CHF 148'200 pro Jahr", value: values[key].an.bbb },
      ],
    };
  }
  result.an.push(item);

  // *** AN NETTOLOHN***
  item = { label_DE: "Nettolohn vor Steuern" };
  for (let key in values) {
    item[key] = {
      value: values[key].bruttolohn - values[key].an_total,
    };
  }
  result.an.push(item);

  return result;
};
