export const option_contractor = [
  { label: "Contractor", value: "contractor" },
  { label: "Payrollee", value: "payrollee" },
];

export const option_sex = [
  { label: "Mann", value: "mann" },
  { label: "Frau", value: "frau" },
];

export const option_age = [
  { label: "0 - 19", value: "0_19" },
  { label: "20 - 24", value: "20_24" },
  { label: "25 - 30", value: "25_30" },
  { label: "31 - 34", value: "31_34" },
  { label: "35 - 44", value: "35_44" },
  { label: "45 - 49", value: "45_49" },
  { label: "50 - 54", value: "50_54" },
  { label: "55 - 65", value: "55_65" },
  { label: "66 - 69 (mit BVG Sparen)", value: "65_69_mit_BVG" },
  { label: ">65 (ohne BVG Sparen)", value: "65_plus_ohne_BVG" },
];

export const option_target_pro = [
  { label: "pro Stunde", value: "pro_stunde" },
  { label: "pro Tag", value: "pro_tag" },
  { label: "pro Monat", value: "pro_monat" },
  { label: "pro Jahr", value: "pro_jahr" },
];

export const option_target_for = [
  { label: "Basissatz", value: "basissatz" },
  { label: "Bruttolohn", value: "bruttolohn" },
  { label: "All-In Lohnkosten", value: "all_in_lohnkosten" },
  { label: "Bill Rate", value: "bill_rate" },
];

export const option_payrolling_fee = [
  { label: "Pauschal Pro Stunde", value: "pauschal" },
  { label: "in % der All-In Kosten", value: "all_in" },
];
