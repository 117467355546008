// IMPORT BASICS
import React from "react";

// IMPORT STYLES
import styles from "./Button.module.scss";

// ****************************************
// BUTTON (Component)
// Draws the button
// ****************************************
const Button = (props) => {
  // PROPS
  const { children, execute } = props;
  return (
    <div onClick={execute} className={styles["container"]}>
      {children}
    </div>
  );
};

export default Button;
