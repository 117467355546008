// IMPORT BASICS
import React, { useState, useCallback } from "react";

// IMPORT CUSTOM
import TextField from "../components/TextField/TextField.js";
import Select from "../components/Select/Select.js";
import Button from "../components/Button/Button.js";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddCircleIcon from "@mui/icons-material/AddCircle";

// IMPORT CUSTOM
import Line from "../components/Line/Line.js";

// IMPORT DATA
import {
  option_contractor,
  option_sex,
  option_age,
  option_target_pro,
  option_target_for,
  option_payrolling_fee,
} from "../data/options.data.js";
import { settings } from "../data/calculation.data.js";

// IMPORT SCRIPTS
import { calculation } from "../scripts/calculation.js";

// IMPORT UTILS
import { format } from "../utils/format.js";
import { getStructure } from "../scripts/getStructure.js";

const Home = () => {
  // STATES
  const [values, setValues] = useState({});
  const [grade1, setGrade1] = useState(null);
  const [structure, setStructure] = useState(null);

  // CALLBACKS
  const updateValues = useCallback(
    (label, value) => {
      const tmpValues = { ...values };
      tmpValues[label] = value;
      setValues(tmpValues);
    },
    [values]
  );

  // FUNCTIONS
  const calculate = () => {
    const result = {
      grade1: calculation(values, settings, "grade1"),
      grade4: calculation(values, settings, "grade4"),
      grade9: calculation(values, settings, "grade9"),
    };
    const tmpStructure = getStructure(result);
    console.log("STRUCTURE", tmpStructure);
    setStructure(tmpStructure);
  };

  return (
    <div className="wrapper">
      <div className="container">
        <h1>Parollee Calculator</h1>
        <Select
          name="contractor"
          updateValues={updateValues}
          placeholder="Enter Name"
          title="Contractor / Payrollee"
          options={option_contractor}
        />
        <Select
          name="sex"
          updateValues={updateValues}
          placeholder="Enter Name"
          title="Geschlecht"
          options={option_sex}
        />
        <Select
          name="age"
          updateValues={updateValues}
          placeholder="Enter Name"
          title="Alterskategorie"
          options={option_age}
        />
        <TextField name="target" updateValues={updateValues} title="Zielwert" addon="CHF" />
        <Select
          name="target_pro"
          updateValues={updateValues}
          placeholder="Enter Name"
          title="Zielwert pro"
          options={option_target_pro}
        />
        <Select name="target_for" updateValues={updateValues} title="Zielwert für" options={option_target_for} />
        <TextField name="client_firm" updateValues={updateValues} title="Kunde Firma" />
        <TextField addon="h" name="stunden_pw" updateValues={updateValues} title="Stunden pro Arbeitswoche" />
        <TextField addon="h" name="stunden_pd" updateValues={updateValues} disabled title="Stunden pro Tag" />
        <Select
          name="payrolling_fee"
          updateValues={updateValues}
          title="Payrolling Fee / Marge"
          options={option_payrolling_fee}
        />
        <Button execute={() => calculate()}>
          <div>Berechnen</div>
        </Button>
      </div>
      {structure ? (
        <div className="table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="50%">
                  <b>Lohnaufschlüsselung Arbeitgeber</b>
                </TableCell>
                <TableCell>
                  <p className="home_headerNumber">
                    <b style={{ textAlign: "right" }}>Grade 1</b>
                  </p>
                </TableCell>
                <TableCell>
                  <p className="home_headerNumber">
                    <b style={{ textAlign: "right" }}>Grade 4</b>
                  </p>
                </TableCell>
                <TableCell>
                  <p className="home_headerNumber">
                    <b style={{ textAlign: "right" }}>Grade 9</b>
                  </p>
                </TableCell>
              </TableRow>
            </TableHead>
            {structure.ag.map((item) => {
              return <Line content={item} />;
            })}
            <TableRow>
              <TableCell>
                <b>Lohnaufschlüsselung Arbeitnehmer</b>
              </TableCell>
              <TableCell>
                <p className="home_headerNumber">
                  <b>Grade 1</b>
                </p>
              </TableCell>
              <TableCell>
                <p className="home_headerNumber">
                  <b style={{ textAlign: "right" }}>Grade 4</b>
                </p>
              </TableCell>
              <TableCell>
                <p className="home_headerNumber">
                  <b style={{ textAlign: "right" }}>Grade 9</b>
                </p>
              </TableCell>
            </TableRow>
            {structure.an.map((item, index) => {
              return <Line content={item} />;
            })}
          </Table>
        </div>
      ) : null}
    </div>
  );
};

export default Home;
